import React from "react";

const DropdownHeading = ({ data }) => {
  return (
    <div className="Dropdown-group">
      <div className="Dropdown-groupHead">
        <img
          className="Dropdown-logo Dropdown-logo--head"
          width="16"
          height="16"
          src={data.logo}
          alt="logo"
        />
        <span>{data.label}</span>
      </div>
      <span className="Dropdown-groupLength">
        {data.options.length - 1} club(s)
      </span>
    </div>
  );
};
export default DropdownHeading;
